export const removeCoordsDuplicated = (coords) => {
    let newCoords = [];

    let temp_start = 0;
    let temp_end = 0;

    for (let coord of coords) {
        if (coord[0] === temp_start || coord[1] === temp_end) continue;
        newCoords.push(coord);
        temp_start = coord[0];
        temp_end = coord[1];
    }

    return newCoords;
}