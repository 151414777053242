async function postRequest(data, url) {
    const response = await fetch(url, {
        method: "post",
        body: JSON.stringify(data),
        headers: {"Content-Type": "application/json"},
    });
    if (response.ok) return response.json();
    throw response;
}

export async function predictCorrosionCollection(data) {
    return postRequest(
        data,
        "https://cuibackend.azurewebsites.net/predict_corrosion_classes"
    );
}

export async function predictCorrosionLabel(data) {
    return postRequest(
        data,
        "https://cuibackend.azurewebsites.net/predict_corrosion_class"
    );
}

export async function saveManualFeedback(data) {
    return postRequest(
        data,
        "https://cuibackend.azurewebsites.net/save_manual_feedback");
}

export async function parseTextForNls(text) {
    return postRequest(
        text,
        "https://cuibackend.azurewebsites.net/nls_analysis"
    )
}

export async function getHelloWorld() {
    const response = await fetch("https://cuibackend.azurewebsites.net/hello_world", {
        method: "get",
        headers: {"Content-Type": "application/json"},
    });
    if (response.ok) return response.json();
    throw response;
}