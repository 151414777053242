import {removeCoordsDuplicated} from "./coordinatesUtils";
import parse from "html-react-parser";

export const renderNlsText = (text, coords) => {
    if (text !== null && coords !== null) {
        const mark_end = "</mark>";
        const classMap = new Map();
        classMap.set("Equipment", "eqpm");
        classMap.set("Location", "lctn");
        classMap.set("Size", "size");

        let mark_eqp_start = `<mark classname="marked-eqpm">`;
        let offset = mark_eqp_start.length + mark_end.length;
        let coordinates = removeCoordsDuplicated(coords);

        for (let i = 0; i < coordinates.length; i++) {

            let start = coordinates[i][0] + offset * i;
            let end = coordinates[i][1] + offset * i;

            mark_eqp_start = `<mark classname="marked-${classMap.get(
                coordinates[i][2]
            )}">`;

            text =
                text.substring(0, start) +
                mark_eqp_start +
                text.substring(start, end) +
                mark_end +
                text.substring(end);
        }
        return parse(text);
    } else {
        return parse("")
    }
};