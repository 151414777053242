import React, {useEffect, useState} from "react";
import {DotLoader} from "react-spinners";
import Dropzone from "./Dropzone";
import DataTable from "./Metadata";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {parseTextForNls, predictCorrosionCollection,} from "./services/nlpService";
import CorrosionLabel from "./CorrosionLabel";
import {renderNlsText} from "./utils/highlightText";
import {arrayToDict} from "./utils/dataStructureUtils";
import LoaderStatus from "./LoaderStatus";

export default function ParsePdf() {

    const [pdf, setPdf] = useState(() => {
        try {
            // return JSON.parse(localStorage.getItem("pdf")) ?? [];
            return [];
        } catch {
            console.error("The pdf can't be read from the local browser memory.");
            return [];
        }
    });


    const [statusMessage, setStatusMessage] = useState("Please wait...");
    const [status, setStatus] = useState("Parsing PDF");
    const [progress, setProgress] = useState(0);
    const [narrativesFound, setNarrativesFound] = useState(0);
    const [narrativesParsed, setNarrativesParsed] = useState(0);

    const [selected, setSelected] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [corrosionClass, setCorrosionClass] = useState("");

    const [nlsTexts, setNlsTexts] = useState(() => {
        try {
            return arrayToDict(JSON.parse(localStorage.getItem("nls")) ?? []);
        } catch {
            console.error(
                "The nls data can't be read from the local browser memory."
            );
            return [];
        }
    });

    useEffect(() => {
        localStorage.setItem("pdf", JSON.stringify(pdf), [pdf]);
        localStorage.setItem("nls", JSON.stringify(nlsTexts), [nlsTexts]);
    });

    const sx_content = {marginTop: "0.5em", overflowX: "visible"};
    const sx_summary = {marginTop: "0em"};
    const padding = "4px";

    const updateProgress = (unit) => {
        let percentage = (narrativesFound + unit) / narrativesParsed
        setProgress(percentage)
    }

    const predictCorrosionClass = (data) => {
        setStatus("Predicting Corrosion")
        predictCorrosionCollection(data).then((response) => {
            const pdfWithPredictions = data.map((item, index) => {
                return {...item, predictedCorrosion: response[index], "id": index};
            });
            setLoading(false);
            setPdf(pdfWithPredictions);
            setStatusMessage("Please wait...");
            setStatus("Parsing PDF")
        }).catch((error) => {
            // console.log("** error", error);
            // console.log("** statusText", error["statusText"]);
            setPdf([])
            setStatus("Error");
            setStatusMessage(error["statusText"]);
        });
    };

    const closeDialog = () => {
        setLoading(false);
        setStatusMessage("Please wait...");
        setStatus("Parsing PDF")
    }

    const savePdf = (data) => {
        setCorrosionClass("All");
        const pdfData = data.map((item) => {
            return {...item, predictedCorrosion: "", manualCorrosionLabel: ""};
        });
        setNarrativesFound(data.length)
        setStatusMessage(data.length.toString() + " ECH Narratives found. Please Wait...")
        setPdf(pdfData);
        getNlsData(pdfData);
        predictCorrosionClass(pdfData);
    };

    const getNlsData = (text) => {
        parseTextForNls(text).then((response) => {
            setNlsTexts(arrayToDict(response));
        });
    };


    const echFilteredByCorrosion = [
        "No Corrosion",
        "Minor Corrosion",
        "Major Corrosion",
    ].includes(corrosionClass)
        ? pdf.filter((p) => p["predictedCorrosion"] === corrosionClass)
        : pdf;

    const render_ech_data = (index, id) => {
        if (index !== null) {
            return (
                <>
                    <DataTable
                        ech_record={echFilteredByCorrosion[index]}
                        sx={sx_content}
                        corrosionDamage={echFilteredByCorrosion[index]["predictedCorrosion"]}
                    />

                    <p align="justify" style={{whiteSpace: "pre-line"}}>
                        {renderNlsText(
                            echFilteredByCorrosion[index]["EquipmentRecords"][0][
                                "NarrativeECHS"
                                ][0]["Narrative"],
                            nlsTexts[id]["Coordinates"]
                        )}
                    </p>
                    <CorrosionLabel
                        key={index}
                        record={
                            echFilteredByCorrosion[index]["EquipmentRecords"][0][
                                "NarrativeECHS"
                                ][0]["Narrative"]
                        }
                        predictedLabel={echFilteredByCorrosion[index]["predictedCorrosion"]}
                    />
                </>
            );
        } else {
            return <></>;
        }
    };

    const changed_corrosion_class = (corrosion_class) => {
        setCorrosionClass(corrosion_class);
        selectNarrative(null);
    };

    const selectNarrative = (index, id) => {
        setSelected(index);
        setSelectedId(id);
    }

    const render_narratives = () => {
        if (loading) {
            // return <DotLoader className="loader" color="#ffd500"/>;
            return <LoaderStatus open={true} progress={progress} status={status} dialogContent={statusMessage}
                                 handleClose={() => closeDialog()}/>
        } else {
            return (
                <>
                    <div className="summary side-bar custom-scrollbar">
                        {pdf.length > 0 && (
                            <FormControl size="small" sx={{marginTop: 1, marginRight: 1}}>
                                <InputLabel id="corrosion-select">Corrosion Type</InputLabel>
                                <Select
                                    labelId="corrosion-select"
                                    id="corrosion-group-select"
                                    value={corrosionClass}
                                    label="Corrosion Type"
                                    onChange={(e) => changed_corrosion_class(e.target.value)}
                                >
                                    <MenuItem value={"All"}>All</MenuItem>
                                    <MenuItem value={"No Corrosion"}>No Corrosion</MenuItem>
                                    <MenuItem value={"Minor Corrosion"}>Minor Corrosion</MenuItem>
                                    <MenuItem value={"Major Corrosion"}>Major Corrosion</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </div>
                    <div className="summary side-bar custom-scrollbar" style={{marginTop: 60}}>
                        {echFilteredByCorrosion.map((item, index) => {
                            return (
                                <div
                                    key={index}
                                    tabIndex={index}
                                    className="summary-item custom-scrollbar"
                                    onClick={() => selectNarrative(index, item['id'])}
                                >
                                    <DataTable
                                        ech_record={item}
                                        sx={sx_summary}
                                        padding={padding}
                                        corrosionDamage={item["predictedCorrosion"]}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <div className="content summary">{render_ech_data(selected, selectedId)}</div>
                </>
            );
        }
    };

    return (
        <>
            <Dropzone setPdf={savePdf} setLoading={setLoading}/>
            {render_narratives()}
        </>
    );
}
