import React, {useCallback, useMemo} from "react";
import {
    Backdrop,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid
} from "@mui/material";
import CircularStatic from "./CircularStatic";
import Button from "@mui/material/Button";


export default function LoaderStatus({open, progress, status, dialogContent = "", handleClose}) {

    return (<div className="loader">
        <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={open}
        >
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={{alignContent: "center", alignItems: "center", justifyContent: "center"}}
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "400px",  // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="alert-dialog-title">
                    {status}
                </DialogTitle>
                <DialogContent style={{alignContent: "center", alignItems: "center", justifyContent: "center"}}>
                    <DialogContentText id="alert-dialog-description">
                        {dialogContent}
                    </DialogContentText>
                    {status !== "Error" &&
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            style={{minHeight: "10vh"}}
                        >
                            <CircularStatic
                                style={{alignContent: "center", alignItems: "center", justifyContent: "center"}}
                                progress={progress}/>
                        </Grid>
                    }
                    {status === "Error" &&
                        <DialogActions>
                            <Button autoFocus onClick={handleClose}>
                                OK
                            </Button>
                        </DialogActions>
                    }
                </DialogContent>
            </Dialog>

        </Backdrop>
    </div>);
}
