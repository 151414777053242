import React from "react";
import { Link, NavLink } from "react-router-dom";

const activeStyle = {
  color: "purple",
};

export default function Header() {
  return (
    <header>
      <nav>
        <ul>
          <li>
            <Link to="/">
              <img id="shell_logo" alt="Shell" src="/images/logo.svg" />
            </Link>
          </li>
          <li id="header_text">CUI NLP</li>
          <li>
            <NavLink
              to="/"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              Parse PDF
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/text"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              Parse Text
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
              to="/pdf"
            >
              Parse PDF
            </NavLink>
          </li> */}
        </ul>
      </nav>
    </header>
  );
}
