import React, {useCallback, useMemo} from "react";
import {useDropzone} from "react-dropzone";

const baseStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",

    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    transition: "border .3s ease-in-out",
};

const activeStyle = {
    borderColor: "#2196f3",
};

const acceptStyle = {
    borderColor: "#ffd500",
};

const rejectStyle = {
    borderColor: "#ff1744",
};

export default function dropzone({setPdf, setLoading}) {
    const onDrop = useCallback((acceptedFiles) => {
        setLoading(true);
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = () => {
                const parsePdf = (data) => {
                    fetch("https://cuibackend.azurewebsites.net/", {
                        method: "post",
                        body: data,
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            setPdf(data);
                            // setLoading(false);
                        })
                        .catch((e) => {
                            console.error(e);
                            setLoading(false)
                        })
                        .finally(() => {
                        });
                };

                parsePdf(reader.result);
            };
            reader.readAsDataURL(file);
        });
    }, []);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
    });

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isDragActive, isDragReject, isDragAccept]
    );

    return (
        <div className="dropzone" {...getRootProps({style})}>
            <input {...getInputProps()} />
            <div>Drop your pdf file or click to select it...</div>
        </div>
    );
}
