import React from "react";
import { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert"
import { Tooltip } from "@mui/material";
import { saveManualFeedback } from "./services/nlpService";
export default function CorrosionLabel({ record, predictedLabel }) {
  const [manualLabel, setManualLabel] = useState("");

  const changed_corrosion_label = (setLabel) => {
    saveManualFeedback({record, manualLabel: setLabel});
    setManualLabel(setLabel);
  };

  const notifyFeedbackReceived = () => {
    if (manualLabel) {
      return <Alert severity="success">The feedback is received!</Alert>
    }
  };

  return (
    <>
      <p style={{ fontWeight: "bold" }}>
        Predicted Corrosion label: {predictedLabel}
      </p>
      <Tooltip
        title={
          <h3>
            If you believe that the automatic prediction is off you could
            provide the correct corrosion type for this text. Your feedback will
            help improve predictions for this tool.
          </h3>
        }
        placement="right-start"
      >
        <FormControl
          sx={{
            m: 1,
            minWidth: 340,
            maxWidth: 340,
            margin: 0,
            marginBottom: 3,
          }}
          size="small"
        >
          <InputLabel id="demo-select-small">
            Manually assign the corrosion type
          </InputLabel>
          <Select
            labelId="demo-select-small"
            id="demo-select-small"
            value={manualLabel}
            label="Manually assign the corrosion type"
            onChange={(e) => changed_corrosion_label(e.target.value)}
          >
            <MenuItem value={"No Corrosion"}>No Corrosion</MenuItem>
            <MenuItem value={"Minor Corrosion"}>Minor Corrosion</MenuItem>
            <MenuItem value={"Major Corrosion"}>Major Corrosion</MenuItem>
          </Select>
        </FormControl>
      </Tooltip>
      {notifyFeedbackReceived()}
    </>
  );
}
