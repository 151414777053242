import {TextField} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import React, {useState} from "react";
import {parseTextForNls, predictCorrosionCollection} from "./services/nlpService";
import CorrosionLabel from "./CorrosionLabel";
import {renderNlsText} from "./utils/highlightText";

export default function ParseText() {
    const [loading, setLoading] = useState(false);
    const [inputText, setInputText] = useState("");
    const [coordinates, setCoordinates] = useState(null);
    const [predictedLabel, setPredictedLabel] = useState(null);
    const [mainText, setMainText] = useState(null);

    const incapsuleTextIntoNarrative = () => {
        return [{
            'EquipmentRecords': [
                {
                    'NarrativeECHS': [
                        {'Narrative': inputText}
                    ]
                }
            ]
        }];
    }

    const predictCorrosionClass = (data) => {
        predictCorrosionCollection(data).then((response) => {
            const predictions = data.map((item, index) => {
                return {...item, predictedCorrosion: response[index], "id": index};
            });
            setLoading(false);
            setPredictedLabel(predictions[0]['predictedCorrosion']);
        });
    };

    const getNlsData = async (text) => {
        const nlsCoordinates = await parseTextForNls(text).then((response) => {
            return response[0]['Coordinates'];
        });
        setCoordinates(nlsCoordinates);
    };

    const handleAnalyzeClick = async () => {
        setLoading(true);
        let narratives = incapsuleTextIntoNarrative();
        setMainText(inputText)
        predictCorrosionClass(narratives);
        await getNlsData(narratives);
    };

    return (
        <>
            <div className="summary side-bar">
                <TextField
                    label="Input your text"
                    variant="outlined"
                    sx={{marginTop: 1, marginRight: 1}}
                    inputProps={{style: {textAlign: "justify"}}}
                    multiline
                    minRows={22}
                    maxRows={22}
                    onChange={(e) => setInputText(e.target.value)}
                />
                <LoadingButton
                    loading={loading}
                    sx={{marginTop: 1, marginRight: 1}}
                    onClick={handleAnalyzeClick}
                    variant={"contained"}
                    color={"error"}
                >
                    Analyze text
                </LoadingButton>
            </div>
            <div className="content">
                <>
                    {mainText ? (
                        <>
                            {/*<p style={{whiteSpace: "pre-line"}}>{analyzedText}</p>*/}
                            <CorrosionLabel
                                record={inputText}
                                predictedLabel={predictedLabel}
                            />
                            <p align="justify" style={{whiteSpace: "pre-line"}}>
                                {
                                    renderNlsText(mainText, coordinates)
                                }
                            </p>
                        </>
                    ) : (
                        <h3>Analyzed text will appear here...</h3>
                    )}
                </>
            </div>
        </>
    );
}
