import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function DataTable({ech_record, sx, padding, corrosionDamage}) {
    function createData(name, value) {
        return {name, value};
    }

    const ech_metadata = [
        createData("Unit", ech_record["Unit"]),
        createData("FLOC", ech_record["FLOC"]),
        createData("Equipment", ech_record["Equipment"]),
        createData("ECH Nr./Name", ech_record["ECH Nr./Name"]),
        createData("ECH Description", ech_record["ECH Description"]),
        createData("Equipment Group", ech_record["EquipmentRecords"][0]["Equipment Group"]),
    ];

    const TableRowElement = (row) => {
        return <TableRow
            key={row.name}
            sx={{"&:last-child td, &:last-child th": {border: 0}}}
        >
            <TableCell
                component="th"
                scope="row"
                sx={{
                    backgroundColor: "lightgray", fontWeight: "bold", fontSize: "0.7em", padding: {padding},
                }}
            >
                {row.name}
            </TableCell>
            <TableCell
                align="left"
                sx={{fontSize: "0.7em", padding: {padding}}}
            >
                {row.value}
            </TableCell>
        </TableRow>

    }

    return (<TableContainer sx={sx} component={Paper}>
        <Table size="small" aria-label="a dense table">
            <TableBody>
                {ech_metadata.map((row) => (TableRowElement(row)))}
                {TableRowElement({"name": "Corrosion Damage", "value": corrosionDamage})}
            </TableBody>
        </Table>
    </TableContainer>);
}
